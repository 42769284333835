import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, IPageDidMountHandler, LoggerSymbol, ILogger } from '@wix/thunderbolt-symbols'
import { SiteMembersWixCodeSdkHandlers, MemberDTO } from '../types'
import { ISiteMembersApi, SiteMembersApiSymbol, PrivacyStatus, INTERACTIONS } from 'feature-site-members'
import { SessionManagerSymbol, ISessionManager } from 'feature-session-manager'
import { ConsentPolicySymbol, IConsentPolicy, ConsentPolicyUpdatesListener } from 'feature-consent-policy'

const SiteMembersWixCodeSdkHandlersFactory = (
	{
		login,
		promptLogin,
		promptForgotPassword,
		applySessionToken,
		getMemberDetails,
		register,
		registerToUserLogin,
		logout,
		handleOauthToken,
	}: ISiteMembersApi,
	sessionManager: ISessionManager,
	logger: ILogger,
	consentPolicyApi: IConsentPolicy
): SdkHandlersProvider<SiteMembersWixCodeSdkHandlers> & IPageDidMountHandler => {
	const sdkListeners: Array<ConsentPolicyUpdatesListener> = []
	const consentUpdatesListener: ConsentPolicyUpdatesListener = (policyDetails, policyHeaderObject) => {
		sdkListeners.forEach((listener) => listener(policyDetails, policyHeaderObject))
	}
	return {
		pageDidMount() {
			const unregister = consentPolicyApi.registerToChanges(consentUpdatesListener)
			return () => unregister()
		},
		getSdkHandlers() {
			return {
				async login(email, password, options) {
					logger.interactionStarted(INTERACTIONS.CODE_LOGIN)
					const response = await login(email, password, options)
					logger.interactionEnded(INTERACTIONS.CODE_LOGIN)
					return response
				},
				applySessionToken,
				promptForgotPassword,
				async promptLogin(options) {
					const { member } = await promptLogin(options)
					return member as MemberDTO // TODO: Maybe consolidate these almost identical types?
				},
				async register(email, password, options) {
					const { member, approvalToken, status } = await register(
						email,
						password,
						options?.contactInfo || { customFields: [] },
						options.privacyStatus || PrivacyStatus.PRIVATE
					)

					return {
						status,
						approvalToken,
						user: member,
					}
				},
				registerToUserLogin,
				logout,
				getMemberDetails,
				handleOauthToken,
				registerToConsentPolicyChanges(listener) {
					sdkListeners.push(listener)
				},
				setConsentPolicy(policy) {
					return consentPolicyApi.setConsentPolicy(policy)
				},
				resetConsentPolicy() {
					return consentPolicyApi.resetConsentPolicy()
				},
			}
		},
	}
}

export const siteMembersWixCodeSdkHandlers = withDependencies(
	[SiteMembersApiSymbol, SessionManagerSymbol, LoggerSymbol, ConsentPolicySymbol],
	SiteMembersWixCodeSdkHandlersFactory
)
